import * as React from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  section: { background: "#222", padding: "32px" },
  pText: { display: "block", marginBottom: "22px" },
}));

export default function SoundCloud() {
  const styles = useStyles();
  return (
    <Box className={styles.section} p={3}>
      <Typography variant="h4" component="h1">
        Live from the Studio
      </Typography>
      <Typography variant="caption" className={styles.pText}>
        Recorded live at The Mayfair Studio (vocal overdubs)
      </Typography>

      <iframe
        title="Green Day Tribute Band, Green Dave"
        width="100%"
        height="300"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1640893903&color=%235cac74&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
    </Box>
  );
}
