import * as React from "react";

import { Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  section: {
    background: "#111",
  },
}));

export default function Booking() {
  const styles = useStyles();
  return (
    <Box className={styles.section} p={3}>
      <h2 id="contact">Booking</h2>
      <p>
        For booking enquiries, email{" "}
        <Link href="mailto:greendaveuk@gmail.com">greendaveuk@gmail.com</Link>
      </p>
    </Box>
  );
}
