import * as React from "react";

import { Box, IconButton, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { StaticImage } from "gatsby-plugin-image";
import { Instagram, YouTube } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  section: { background: "#222" },
  igImage: {
    maxWidth: "30px",
  },
}));

export default function Socials() {
  const styles = useStyles();
  return (
    <Box className={styles.section} p={3}>
      <IconButton href="https://www.instagram.com/greendaveuk/">
        <Instagram />
      </IconButton>

      <IconButton href="https://www.youtube.com/channel/UCO-ZtU2hLUAZ0ClvDYYEIuA">
        <YouTube />
      </IconButton>
    </Box>
  );
}
