import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import {
  YouTube,
  Instagram,
  Theaters,
  // PhotoCamera,
  Movie,
  EventAvailable,
  SupervisedUserCircle,
  ContactMail,
} from "@material-ui/icons";
import { Menu, MenuItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <StaticImage
              src="../images/GreenDave_LogoWhiteTransparent.png"
              alt="Green Dave - UK Green Day Tribute Band"
              height={90}
            />
          </Typography>

          <IconButton
            href="https://www.instagram.com/greendaveuk/"
            target="_blank"
          >
            <Instagram />
          </IconButton>

          <IconButton
            href="https://www.youtube.com/channel/UCO-ZtU2hLUAZ0ClvDYYEIuA"
            target="_blank"
          >
            <YouTube />
          </IconButton>

          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleClose} component={Link} to="/#shows">
              <ListItemIcon fontSize="small">
                <EventAvailable />
              </ListItemIcon>
              <ListItemText>Shows</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/#showreel">
              <ListItemIcon fontSize="small">
                <Movie />
              </ListItemIcon>
              <ListItemText>Showreel</ListItemText>
            </MenuItem>
            {
              <MenuItem onClick={handleClose} component={Link} to="/#video">
                <ListItemIcon fontSize="small">
                  <Theaters />
                </ListItemIcon>
                <ListItemText>Video</ListItemText>
              </MenuItem>
              /*<MenuItem onClick={handleClose}  component={Link} to="/#photos">
              <ListItemIcon fontSize="small">
                <PhotoCamera />
              </ListItemIcon>
              <ListItemText>Photos</ListItemText>
            </MenuItem>
             */
            }
            <MenuItem onClick={handleClose} component={Link} to="/#bio">
              <ListItemIcon fontSize="small">
                <SupervisedUserCircle />
              </ListItemIcon>
              <ListItemText>Bio</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/#contact">
              <ListItemIcon fontSize="small">
                <ContactMail />
              </ListItemIcon>
              <ListItemText>Booking</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="/#promoter-pack"
            >
              <ListItemIcon fontSize="small">
                <EventAvailable />
              </ListItemIcon>
              <ListItemText>Promoter Pack</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="https://www.instagram.com/greendaveuk/"
            >
              <ListItemIcon fontSize="small">
                <Instagram />
              </ListItemIcon>
              <ListItemText>Instagram</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to="https://www.youtube.com/channel/UCO-ZtU2hLUAZ0ClvDYYEIuA"
            >
              <ListItemIcon fontSize="small">
                <YouTube />
              </ListItemIcon>
              <ListItemText>YouTube</ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
