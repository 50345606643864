import * as React from "react";
import { StaticQuery, graphql } from "gatsby";

import { Box, Typography } from "@material-ui/core";

const embedContainer = {
  position: "relative",
  paddingBottom: "56.25%",
  height: 0,
  overflow: "hidden",
  maxWidth: "100%",
  marginBottom: "30px",
};

const embedContainerIFrame = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

export default function Videos() {
  return (
    <StaticQuery
      query={graphql`
        query ContentExample {
          prismicShowreelandvideos {
            data {
              showreel_youtube_playlist_id
              body {
                ... on PrismicShowreelandvideosDataBodyVideoPlaylist {
                  id
                  primary {
                    playlist_title {
                      text
                    }
                    youtube_playlist_id
                  }
                  slice_type
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        if (!data) return null;
        const content = data.prismicShowreelandvideos.data;
        const slices = content.body;

        return (
          <>
            <Box bgcolor="primary.main" px={4} py={2} id="showreel">
              <Typography variant="h3">Showreel</Typography>

              <div style={embedContainer}>
                <iframe
                  style={embedContainerIFrame}
                  src={`https://www.youtube-nocookie.com/embed/?listType=playlist&list=${content.showreel_youtube_playlist_id}`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Box>
            <Box bgcolor="secondary.dark" px={4} py={2} id="video">
              <Typography variant="h3">More Videos</Typography>

              {slices.map((slice) => {
                if (slice.slice_type !== "video_playlist") {
                  return null;
                }

                return (
                  <>
                    <Typography variant="h4">
                      {slice.primary.playlist_title.text}
                    </Typography>
                    <div style={embedContainer}>
                      <iframe
                        style={embedContainerIFrame}
                        src={`https://www.youtube-nocookie.com/embed/?listType=playlist&list=${slice.primary.youtube_playlist_id}`}
                        title={`Green Dave Band ${slice.primary.playlist_title.text}`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </>
                );
              })}
            </Box>
          </>
        );
      }}
    />
  );
}
