import * as React from "react";
import { StaticQuery, graphql } from "gatsby";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  section: {
    background: "#222",
  },
}));

export default function Bio() {
  const styles = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query BioContent {
          prismicBio {
            data {
              bio_title {
                text
              }
              bio_text {
                html
              }
            }
          }
        }
      `}
      render={(data) => {
        if (!data) return null;
        const content = data.prismicBio.data;

        return (
          <Box className={styles.section} p={4} id="bio">
            <h1>{content.bio_title.text}</h1>
            <div dangerouslySetInnerHTML={{ __html: content.bio_text.html }} />
          </Box>
        );
      }}
    />
  );
}
