import * as React from "react";
import { StaticQuery, graphql } from "gatsby";

import { Box, Typography } from "@material-ui/core";

export default function Strapline() {
  return (
    <StaticQuery
      query={graphql`
        query StraplineContent {
          prismicStrapline {
            data {
              strapline_1 {
                text
              }
              strapline_2 {
                text
              }
              strapline_3 {
                text
              }
            }
          }
        }
      `}
      render={(data) => {
        if (!data) return null;
        const content = data.prismicStrapline.data;

        return (
          <Box bgcolor="primary.dark" px={4} py={2}>
            <Typography variant="h4" component="h1">{content.strapline_1.text}</Typography>
            <Typography variant="subtitle1" component="h2">
              {content.strapline_2.text}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {content.strapline_3.text}
            </Typography>
          </Box>
        );
      }}
    />
  );
}
