import * as React from "react";

import { Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  section: {
    background: "#111",
  },
}));

export default function Promoter() {
  const styles = useStyles();
  return (
    <Box className={styles.section} p={3}>
      <h2 id="promoter-pack">Promoter Pack</h2>
      <ul>
        <li>
          <Link href="/files/Green_Dave_Stage_Plan_Tech_Spec.pdf">
            Green Dave - Stage Plan Tech Spec (PDF)
          </Link>
        </li>
        <li>
          <Link href="/files/GreenDave_LogoGreenTransparent.png">
            Green Dave - Logo Green Transparent (PNG)
          </Link>
        </li>
        <li>
          <Link href="/files/GreenDave_LogoWhiteTransparent.png">
            Green Dave - Logo White Transparent (PNG)
          </Link>
        </li>
        <li>
          <Link href="/files/GreenDave_LogoPinkTransparent.png">
            Green Dave - Logo Pink Transparent (PNG)
          </Link>
        </li>
        <li>
          <Link href="/files/GreenDave_LogoBlackTransparent.png">
            Green Dave - Logo Black Transparent (PNG)
          </Link>
        </li>
        <li>
          <Link href="/files/GreenDave_WebsiteLogo.png">
            Green Dave - Kerplunk / American Idiot Alternative Logo Banner (PNG)
          </Link>
        </li>
        <li>
          <Link href="/files/green_dave_promo_photo_1.jpeg">
            Green Dave - Band Promo Photo Landscape (JPG)
          </Link>
        </li>
        <li>
          <Link href="/files/green_dave_promo_photo_2.jpeg">
            Green Dave - Band Promo Photo Portrait (JPG)
          </Link>
        </li>
      </ul>
    </Box>
  );
}
