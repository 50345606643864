import * as React from "react";
import { StaticQuery, graphql } from "gatsby";

import { Box, Typography } from "@material-ui/core";

export default function Gigs() {
  return (
    <StaticQuery
      query={graphql`
        query GigsContent {
          prismicGigs {
            data {
              gig_title {
                text
              }
              gig_text {
                html
              }
            }
          }
        }
      `}
      render={(data) => {
        if (!data) return null;
        const content = data.prismicGigs.data;

        return (
          <Box bgcolor="primary.dark" px={4} py={2} id="shows">
            <Typography variant="h4" component="h1">
              {content.gig_title.text}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: content.gig_text.html }} />
          </Box>
        );
      }}
    />
  );
}
