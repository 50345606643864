import * as React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container, Grid } from "@material-ui/core";

import { StaticImage } from "gatsby-plugin-image";
import Bio from "../components/bio";
import Gigs from "../components/gigs";
import Booking from "../components/booking";
import Promoter from "../components/promoter";
import Socials from "../components/social";
import Nav from "../components/nav";
import Strapline from "../components/strapline";
import Videos from "../components/videos";
import Soundcloud from "../components/soundcloud";

import { green, grey } from "@material-ui/core/colors";

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: green,
    secondary: grey,
  },
  typography: {
    h6: {
      fontFamily: ["League Gothic", "sans-serif"].join(","),
      textTransform: "uppercase",
      fontStyle: "italic",
      textShadow: "2px 2px #000",
    },
    h5: {
      fontFamily: ["League Gothic", "sans-serif"].join(","),
      textTransform: "uppercase",
      fontStyle: "italic",
      textShadow: "2px 2px #000",
    },
    h4: {
      fontFamily: ["League Gothic", "sans-serif"].join(","),
      textTransform: "uppercase",
      fontStyle: "italic",
      textShadow: "2px 2px #000",
    },
    h3: {
      fontFamily: ["League Gothic", "sans-serif"].join(","),
      textTransform: "uppercase",
      fontStyle: "italic",
      textShadow: "3px 2px #000",
    },
    h2: {
      fontFamily: ["League Gothic", "sans-serif"].join(","),
      textTransform: "uppercase",
      fontStyle: "italic",
      textShadow: "3px 2px #000",
    },
    h1: {
      fontFamily: ["League Gothic", "sans-serif"].join(","),
      textTransform: "uppercase",
      fontStyle: "italic",
      textShadow: "3px 2px #000",
    },
  },
});

// markup
const IndexPage = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=League+Gothic&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,600&display=swap"
        />
        <title>Green Dave - Green Day Tribute Band (South of England)</title>
        <meta
          name="description"
          content="Available for gigs, parties, weddings and all those places you'd want a Green Day tribute band"
        />
        <link rel="canonical" href="https://greendave.uk" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Green Dave - Green Day Tribute Band"
        />
        <meta
          property="og:description"
          content="Available for gigs, parties, weddings and all those places you'd want a Green Day tribute band"
        />
        <meta
          property="og:image"
          content="../images/photos/green_dave_green_day_tribute_band.jpeg"
        />
        <meta property="og:url" content="https://greendave.uk" />
        <meta property="og:site_name" content="Green Dave UK" />
        <meta name="geo.region" content="GB" />
        <meta name="geo.placename" content="Guildford" />
        <meta name="geo.position" content="51.2;-0.6" />
        <meta name="ICBM" content="51.2, -0.6" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>
      <CssBaseline />
      <Container maxWidth="md">
        <Nav />
        <Strapline />
        <StaticImage
          src="../images/photos/green_dave_green_day_tribute_american_idiot.jpeg"
          alt="Green Dave UK Green Day Tribute Band playing Dookie"
        />
        <Gigs />
        <Soundcloud />
        <Videos />
        <StaticImage
          src="../images/GreenDave_WebsiteLogo.png"
          alt="Green Dave UK Green Day Tribute Band"
        />
        <StaticImage
          src="../images/photos/green_dave_green_day_tribute_band.jpeg"
          alt="Green Dave UK Green Day Tribute Band"
        />

        <Grid>
          <Grid item>
            <Bio />
          </Grid>
          <Grid item>
            <Booking />
          </Grid>
          <Grid item>
            <Promoter />
          </Grid>
          <Grid item>
            <Socials />
          </Grid>
        </Grid>
        <p>&copy; 1987 not us</p>
      </Container>
    </ThemeProvider>
  );
};

export default IndexPage;
